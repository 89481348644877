import React, { useContext, useEffect } from "react"
import Layout from "layout"
import Container from "layout/Container"
import Hero from "layout/Hero"
import Collapsible from "../../../Elements/Collapsible"

import CompleteProfileModal from "../../../Consultations/Home/CompleteProfileModal"
import CoachingVsCounseling from "../CoachingVsCounseling"

import HomeHowItWorks from "../HomeHowItWorks"
import useHomeImages from "../../hooks/useHomeImages"

import { isBrowser } from "services/general"
import {
  hasSignedInUser,
  isProfileComplete,
} from "components/Auth/services/user"

import { AppContext } from "../../../../context/AppContext"

import { GATSBY_TELECONSULT_WEBSITE_URL } from "gatsby-env-variables"

const Home = (props) => {
  let { pageContext } = props
  const { dispatch } = useContext(AppContext)
  const data = useHomeImages()
  const heart = data.heart.childImageSharp.fluid.src

  useEffect(() => {
    if (isBrowser()) {
      dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
      if (hasSignedInUser() && !isProfileComplete()) {
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: <CompleteProfileModal />,
          },
        })
      }
    }
  }, [dispatch])

  const handleOnClick = ({ name, url }) => {
    // urls will be placed in env after features completed
    if (isBrowser()) {
      localStorage.setItem("url", url)
      localStorage.setItem("program", name)
    }

    switch (true) {
      case hasSignedInUser() && !isProfileComplete():
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: <CompleteProfileModal />,
          },
        })
        break

      case !!hasSignedInUser():
        window.location.href = `${GATSBY_TELECONSULT_WEBSITE_URL}booking/[$URL]/intake?program=[$PROGRAM]`
          .replace("[$URL]", url)
          .replace("[$PROGRAM]", name)
        break
      default:
        window.location.href = `/sign-in?program=[$PROGRAM]`.replace(
          "[$PROGRAM]",
          name
        )
        break
    }
  }

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Teleconsult"
      pageContext={pageContext}
    >
      <Hero
        color="light"
        background={{
          image: heart,
          position: "center",
        }}
        className="pt-6 pb-6"
      >
        <Container isCentered desktop={10} fullhd={8}>
          <div>
            <h1 className="title is-size-2 is-size-2-mobile has-text-primary">
              Thank you for your engagement with the <br></br>Genpact Mental Health Program
            </h1>
            <p className="subtitle is-size-5 pt-2 has-text-grey-dark">
              Our partnership with Genpact for mental health services ended on<br/> December 31, 2023. For more information see below.
            </p>
            <br/>
          </div>
        </Container>
      </Hero>
      <Container isCentered mobile={12} tablet={10} desktop={10} fullhd={8} padding={2}>
        <h1 className="is-size-3 is-size-2-mobile has-text-primary">Frequently Asked Questions:</h1>
          <h1 className="is-size-4 is-size-2-mobile">Genpact Mental Health Program</h1>
          <Collapsible title="Who will see me after December 31, 2023?">
            Should you need further help, the Genpact Wellness & Resiliency Coaches are there to facilitate a smooth transition of service to the new provider and/or the Employee Assistance Program (EAP) provider. Please reach out to your onsite Genpact Wellness & Resiliency Coach or Wellness Lead, Ivy Reyes.
          </Collapsible>
          <Collapsible title="How do I reach the new provider?">
            For more information on Genpact’s new  provider, please reach out to your Genpact Wellness & Resiliency Coach or Wellness Lead, Ivy Reyes.
          </Collapsible>
          <Collapsible title="I already tried but can’t seem to access or reach the EAP provider. What should I do?">
            If you are encountering issues with access to the Employee Assistance Program (EAP) provider, you may reach out to any Genpact Wellness & Resiliency Coach onsite. They will be more than happy to help you with the resources you need.
          </Collapsible>
          <Collapsible title="Who can I contact if I have questions or need more information?">
            For any questions regarding the transition, you may reach out to a  Genpact Wellness & Resiliency Coach or your Wellness Lead, Ivy Reyes.
          </Collapsible>
          <Collapsible title="What if I’m in an emergency mental health situation? Who can I reach out to?">
            Genpact services for mental health crises are still available, and will continue to do so after their new partner has begun their services. There are multiple avenues for you to get help.
            <br/> <br/>
            You may reach out to your site’s you may reach out to a Genpact Wellness & Resiliency Coach,  directly contact the Employee Assistance Program (EAP) Provider or your Wellness Lead, Ivy Reyes.
          </Collapsible>

          <h1 className="is-size-4 is-size-2-mobile">MedGrocer Mental Health Program</h1>
          <Collapsible title="I want to keep seeing my assigned mental health professional. Can I continue with MedGrocer and pay the fees directly?">
            The MedGrocer Mental Health Program is currently only available for our partner companies and HMO. You may however reach out to our mental health professionals for information regarding their private practice and clinic hours outside of MedGrocer.
          </Collapsible>

          <h1 className="is-size-4 is-size-2-mobile">Data and Personal Information</h1>
          <Collapsible title="Am I allowed to share post-consultation abstracts and other documents with the new provider?">
          Yes, you have ownership of your mental health information such as abstracts and post-consult documents. We highly encourage you to share abstracts and other information that may help your new provider to support you better. The post-consult documents are sent to you after attending your consultations with MedGrocer. Should you need assistance, please email <a href="mailto: mentalhealth@medgrocer.com">mentalhealth@medgrocer.com</a>.
          </Collapsible>
          <Collapsible title="Who can I contact regarding my personal data?">
          Our Data Privacy Officer provides support regarding data privacy-related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following:
            <ul>
              <li>Mail: MG Health Solutions, Inc. (MedGrocer)</li>
              <li>24F Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City</li>
              <li>Call: <a href="tel:+0283323768">02 8332 3768</a></li>
              <li>Email: <a href="mailto:dpo@medgrocer.com">dpo@medgrocer.com</a></li>
            </ul>
          </Collapsible>

          <h1 className="is-size-4 is-size-2-mobile">For other concerns, feel free to reach us at: <a href="mailto:wellness@medgrocer.com">wellness@medgrocer.com</a></h1>

      </Container>
    </Layout>
  )
}

export default Home
